html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  background-color: #f4f5f7;
}

/* #root {
  height: 100%;
  width: 100%;
}

button {
  padding: 10px 20px;
  margin: 10px;
  border: none;
  border-radius: 4px;
  background-color: #0052cc;
  color: white;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

button:hover {
  background-color: #003d99;
}

.clear-button {
  background-color: #f44336;
}

.clear-button:hover {
  background-color: #d32f2f;
}

header {
  background-color: #0747a6;
  color: white;
  padding: 1em;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

footer {
  padding: 1em;
  text-align: center;
  background-color: #ebecf0;
  border-top: 1px solid #dfe1e6;
  display: flex;
  justify-content: center;
}

textarea {
  background-color: #fff;
  color: #333;
  border: 1px solid #dfe1e6;
  resize: none;
  width: 100%;
  height: 100%;
  padding: 10px;
  border-radius: 4px;
}

.copy-button {
  background-color: #0052cc;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  display: block;
  text-align: center;
  margin-top: 10px;
}

.copy-button:hover {
  background-color: #003d99;
} */
